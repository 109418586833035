import { CognitoUser } from '@aws-amplify/auth';

export const CUSTOM_ATTR_FIRST_NAME = 'custom:firstName';
export const CUSTOM_ATTR_LAST_NAME = 'custom:lastName';
export const CUSTOM_ATTR_PHONE_NUMBER = 'custom:phoneNumber';
export const CUSTOM_ATTR_SMS_ENABLED = 'custom:smsEnabled';

export type CustomUserAttributes = Record<
  | typeof CUSTOM_ATTR_FIRST_NAME
  | typeof CUSTOM_ATTR_LAST_NAME
  | typeof CUSTOM_ATTR_PHONE_NUMBER
  | typeof CUSTOM_ATTR_SMS_ENABLED,
  string
>;

export type UserAttributes = {
  /** Cognito user pool user id (guaranteed globally unique) */
  sub: string;

  /** Whether the user has verified their email yet */
  email_verified: boolean;

  /** The user's claimed email address */
  email: string;
} & CustomUserAttributes;

export interface CurrentUserInfo {
  /** Cognito identity pool identity id */
  id: string;

  /** Cognito user pool username */
  username: string;

  /** Cognito user attributes */
  attributes: UserAttributes;
}

export type CurrentAuthenticatedUser = CognitoUser & {
  username: string;
  attributes: UserAttributes;
};
