import { CottageBanner } from 'components/Common/CottageBanner/style';
import { CottageMarkdown } from 'components/Common/CottageMarkdown/style';

const inputSelectStyles = {
  variants: {
    outline: {
      field: {
        _focus: {
          borderColor: 'brand.400',
          boxShadow: '0 0 0 1px gray',
        },
      },
    },
  },
  sizes: {
    md: {
      field: {
        w: 'full',
      },
    },
  },
};

const components = {
  Modal: {
    baseStyle: {
      dialogContainer: {
        '@supports(min-height: -webkit-fill-available)': {},
      },
    },
  },
  Button: {
    baseStyle: {
      _focus: {
        boxShadow: 'none',
      },
      fontWeight: '400',
    },
    variants: {
      darker: {
        lineHeight: '24px',
        fontSize: '14px',
        bg: 'primary',
        p: '14px 24px',
        color: 'white',
        borderRadius: '15px',
        height: '51px',
        textTransform: 'uppercase',
        fontWeight: '400',
        _loading: {
          bg: 'primary',
          _disabled: {
            bg: 'primary',
          },
        },
        _hover: {
          bg: 'brand.400',
        },
        _disabled: {
          _hover: {
            bg: 'primary !important',
          },
        },
      },
      lighter: {
        lineHeight: '24px',
        fontSize: '14px',
        bg: 'brand.100',
        p: '14px 24px',
        color: 'primary',
        borderRadius: '15px',
        height: '51px',
        textTransform: 'uppercase',
        fontWeight: '400',
        _hover: {
          bg: 'brand.200',
        },
      },
      'underline-link': {
        display: 'inline-flex',
        p: 0,
        h: 'auto',
        border: 'none',
        outline: 'none',
        whiteSpace: 'nowrap',
        textDecorationLine: 'underline',
        color: 'primary',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
      },
      'lighter-sm': {
        fontSize: '19px',
        borderRadius: '11px',
        bg: 'brand.200',
        color: 'primary',
        fontWeight: '500',
        _hover: {
          bg: 'brand.400',
        },
        padding: '10px 16px',
      },
      'light-dark-green': {
        fontSize: 'md',
        bg: 'primary',
        color: 'white',
        fontWeight: 'semibold',
        _hover: {
          bg: 'brand.400',
        },
      },
    },
  },
  Accordion: {
    baseStyle: {
      button: {
        _focus: {
          boxShadow: 'none',
        },
      },
    },
  },
  CloseButton: {
    baseStyle: {
      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },
    },
  },
  Link: {
    baseStyle: {
      _focus: {
        boxShadow: 'none',
      },
    },
  },
  Tab: {
    baseStyle: {
      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },
    },
  },
  FormLabel: {
    baseStyle: {
      fontWeight: 500,
      textStyle: 'md',
      color: 'gray.900',
      fontSize: '14px',
    },
  },
  Input: {
    ...inputSelectStyles,
  },
  Select: {
    ...inputSelectStyles,
  },
  NumberInput: {
    ...inputSelectStyles,
  },
  Textarea: {
    baseStyle: {},
    sizes: {
      md: {},
    },
    variants: {
      outline: {
        _focus: {
          borderColor: 'brand.400',
          boxShadow: '0 0 0 1px',
        },
      },
    },
    defaultProps: {
      variant: 'outline',
    },
  },
  Checkbox: {
    baseStyle: {
      control: {
        colorScheme: 'brand',
        _focus: {
          ring: 2,
          ringColor: 'brand.300',
          boxShadow: 'none',
        },
        _checked: {
          bg: 'primary',
        },
      },
    },
    variants: {
      primary: {
        control: {
          borderColor: 'primary',
          ringColor: '',
        },
      },
    },
  },
  Radio: {
    baseStyle: {},
    defaultProps: {
      colorScheme: 'brand',
      size: 'md',
    },
  },
  CottageMarkdown,
  CottageBanner,
};

export default components;
