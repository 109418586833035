import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'container',
  'p',
  'em',
  'strong',
  'a',
  'del',
  'hr',
  'ul',
  'ol',
  'li',
]);

// The default styling applied to rendered markdown components
export const CottageMarkdown = helpers.defineMultiStyleConfig({
  baseStyle: {
    container: {
      display: 'block',
      pt: '0.75rem',
    },
    p: {
      lineHeight: 1.25,
      pb: '0.75rem',
    },
    ul: {
      pb: '0.75rem',
      pl: { base: '0', md: '1rem' },
    },
    ol: {
      pb: '0.75rem',
      pl: { base: '0', md: '1rem' },
    },
  },
  sizes: {
    sm: {
      container: {},
    },
    md: {
      container: {},
    },
    lg: {
      container: {},
    },
  },
  variants: {
    regular: {
      // renders all supported Markdown elements (basically everything except tables and code blocks)
    },
    slim: {
      // renders only textual content (paragraphs + links + lists)
      // intended for things like the product list view
    },
    compact: {
      // renders only a small amount of text content (intended for things like banners)
    },
  },
  defaultProps: {
    variant: 'slim',
    size: 'md',
  },
});
