import { BusinessMetadata } from 'custom-types';
import Head from 'next/head';
import { FC, useEffect } from 'react';
import { getLogger } from 'utils';

interface MarketingConnectionProps {
  marketing: BusinessMetadata['marketing'];
}

export const MarketingConnection: FC<MarketingConnectionProps> = ({ marketing }) => {
  const log = getLogger('MarketingConnection');
  const { integrationScript } = marketing ?? {};

  const badScript = integrationScript && integrationScript.startsWith('<script');
  useEffect(() => {
    if (badScript) {
      log.error(`misconfigured integrationScript (remove the script tags)`);
    }
  }, [badScript]);

  if (!integrationScript || badScript) {
    return null;
  }

  return (
    <Head>
      <script id="mcjs" dangerouslySetInnerHTML={{ __html: integrationScript }} />
    </Head>
  );
};
