import { BusinessMetadata } from 'custom-types';
import omit from 'lodash/omit';
import values from 'lodash/values';
import Head from 'next/head';
import { FC } from 'react';
import ImageHelpers from 'utils/helpers/ImageHelpers';

interface IOpenGraph {
  businessMetadata: BusinessMetadata;
}

const OpenGraph: FC<IOpenGraph> = ({ businessMetadata }) => {
  const { social, coverImage, avatarImage, title, headline, subdomain, customDomain } =
    businessMetadata;

  const cottageTld = `mycottage${process.env.NEXT_PUBLIC_STAGE === 'devo' ? '-devo.link' : '.menu'}`;
  const canonicalDomain = customDomain ?? `${subdomain}.${cottageTld}`;
  const canonicalUrl = `https://${canonicalDomain}`;

  const coverImageUrl = ImageHelpers.resolveImage(
    'assets/shop/default_cover_image.png',
    coverImage
  );

  const avatarImagePathOrUrl = avatarImage
    ? ImageHelpers.resolveAssetImage(avatarImage)
    : '/favicon.ico';
  const avatarImageAbsoluteUrl = avatarImagePathOrUrl.startsWith('/')
    ? `${canonicalUrl}${avatarImagePathOrUrl}`
    : avatarImagePathOrUrl;

  const twitterMetaTags = [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: headline.primary },
    { name: 'twitter:image:src', content: coverImageUrl },
  ];

  if (social.twitter) {
    twitterMetaTags.push({ name: 'twitter:site', content: `@${social.twitter.split('/')[1]}` });
  }

  // see https://ahrefs.com/blog/open-graph-meta-tags/#which-open-graph-tags-to-use
  const facebookMetaTags = [
    { property: 'og:title', content: title },
    { property: 'og:type', content: 'website' },
    {
      property: 'og:url',
      content: canonicalUrl,
    },
    { property: 'og:image', content: coverImageUrl },
    { property: 'og:image:alt', content: 'Cover Image' },
    {
      property: 'og:image:type',
      content: ImageHelpers.inferMimeType(coverImageUrl) ?? 'image/png',
    },
    { property: 'og:image:width', content: '1200' },
    { property: 'og:image:height', content: '630' },

    { property: 'og:description', content: headline.primary },
    { property: 'og:site_name', content: title },
  ];

  const allSocialUrls = values(omit(social, '__typename'))
    .filter((v) => v != null)
    .map((url) => `https://${url}`);

  const schemaWebsiteData = {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    name: title,
    // If Google decides the website name isn't unique enough, it will use one of the fallback `alternateName` values.
    // For now, let's just fall back to the website domain (this was the default anyway).
    alternateName: [canonicalDomain],
    url: canonicalUrl,
  };

  const schemaOrganizationData = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: title,
    url: canonicalUrl,
    logo: avatarImageAbsoluteUrl,
    sameAs: allSocialUrls,
  };

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={headline.primary} />
      <link rel="icon" href={avatarImagePathOrUrl} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta itemProp="name" content={title} />
      <meta itemProp="description" content={headline.primary} />
      <meta itemProp="image" content={coverImageUrl} />
      {facebookMetaTags.map(({ property, content }) => (
        <meta key={property} property={property} content={content} />
      ))}
      {twitterMetaTags.map(({ name, content }) => (
        <meta key={name} name={name} content={content} />
      ))}
      {/* Schema.org tags */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaWebsiteData) }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaOrganizationData) }}
      />
    </Head>
  );
};

export default OpenGraph;
