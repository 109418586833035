import { ConversionEvent } from 'constants/analytics';
import { MetaStandardEvent } from 'constants/analytics/vendors';

import { AdEventPublisher, ConversionMetadata } from './EventPublisher';

export class MetaEventPublisher implements AdEventPublisher {
  public readonly name = 'Meta';
  public static readonly instance = new MetaEventPublisher();

  public publish(event: ConversionEvent, metadata?: ConversionMetadata) {
    const fbq = MetaEventPublisher.getFbq();
    if (!fbq) {
      return;
    }

    // The ConversionEvent is 1:1 to the Meta standard events spec
    const metaEvent = event as string as MetaStandardEvent;

    if (metadata) {
      fbq('track', metaEvent, metadata);
    } else {
      fbq('track', metaEvent);
    }
  }

  private static getFbq() {
    if (typeof window !== 'undefined' && typeof window.fbq !== 'undefined') {
      return window.fbq;
    }
    return undefined;
  }
}
