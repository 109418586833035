import { DateTime } from 'luxon';

export async function measure<T>(key: string, fn: () => Promise<T>): Promise<T> {
  console.log(`Running ${key}`);
  const startTime = DateTime.now().toMillis();

  try {
    return await fn();
  } finally {
    const endTime = DateTime.now().toMillis();
    console.log(`${key} took ${endTime - startTime} ms`);
  }
}
