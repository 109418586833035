export default class ImageHelpers {
  static resolveStaticImage = (fallbackSuffix: string) =>
    `https://${process.env.NEXT_PUBLIC_STATIC_DOMAIN}/${fallbackSuffix}`;

  static resolveAssetImage = (imageLocation: string) =>
    `https://${process.env.NEXT_PUBLIC_ASSET_DOMAIN}/public/${imageLocation}`;

  static isAssetImage = (imageUrl: string) =>
    imageUrl.startsWith(ImageHelpers.resolveAssetImage(''));

  static resolveImage = (fallbackSuffix: string, imageLocation: string | null | undefined) => {
    return imageLocation
      ? ImageHelpers.resolveAssetImage(imageLocation)
      : ImageHelpers.resolveStaticImage(fallbackSuffix);
  };

  static inferMimeType = (imageLocation: string | null | undefined): string | undefined => {
    const fileExtension = imageLocation?.split('.').at(-1);
    if (fileExtension) {
      const imageType = fileExtension.toLowerCase();
      switch (imageType) {
        case 'png':
        case 'bmp':
        case 'gif':
        case 'webp':
        case 'avif':
          return `image/${imageType}`;
        case 'jpeg':
        case 'jpg':
          return 'image/jpeg';
        case 'svg':
          return 'image/svg+xml';
        case 'ico':
          return 'image/x-icon';
        default:
          break;
      }
    }
    return undefined;
  };
}
