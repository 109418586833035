export const getSemanticTokens = (colorPrefix: string) => ({
  colors: {
    primary: `${colorPrefix}.500`,
    brand: {
      50: `${colorPrefix}.50`,
      100: `${colorPrefix}.100`,
      200: `${colorPrefix}.200`,
      300: `${colorPrefix}.300`,
      400: `${colorPrefix}.400`,
      500: `${colorPrefix}.500`,
      600: `${colorPrefix}.600`,
      700: `${colorPrefix}.700`,
      800: `${colorPrefix}.800`,
      900: `${colorPrefix}.900`,
    },
  },
});
