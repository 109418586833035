import { useToken } from '@chakra-ui/react';
import { ColorTheme } from 'API';

export default class ColorHelpers {
  /**
   * @param colorTheme selected color theme
   * @param defaultColorName the cottage color scheme
   * @returns the color key in the color pallette
   */
  static retrieveColor = (colorTheme: ColorTheme, defaultColorName: string) =>
    colorTheme === ColorTheme.DEFAULT
      ? defaultColorName
      : colorTheme.replaceAll('_', '-').toLowerCase();

  /**
   * @returns the primary color's hex code, fallback to dark gray
   */
  static getColorStrokeHex = () => {
    const [brand500] = useToken(
      // the key within the theme, in this case `theme.colors`
      'colors',
      // the subkey(s), resolving to `theme.colors.red.100`
      ['brand.500'],
      // gray.900, hardcoded for tests
      ['#171923']
    );
    return brand500;
  };
}
