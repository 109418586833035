import 'styles/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ChakraProvider } from '@chakra-ui/react';
import { ColorTheme } from 'API';
import { GAnalytics } from 'components/GoogleAnalytics';
import LoginEnforcer from 'components/LoginEnforcer';
import { MarketingConnection } from 'components/MarketingConnection';
import OpenGraph from 'components/OpenGraph';
import { SupportWidget } from 'components/SupportWidget';
import { PageRoutes } from 'constants/Routes';
import { AmplifyProvider } from 'context/Amplify';
import { AnalyticsProvider } from 'context/Analytics';
import { AppContextProvider } from 'context/AppContext';
import { FrontendMonitoringProvider } from 'context/FrontendMonitoring';
import { ReactQueryProvider } from 'context/ReactQuery';
import { StripeContextProvider } from 'context/StripeContext';
import { ICommonAppProps } from 'custom-types';
import { AppProps } from 'next/app';
import NextNProgress from 'nextjs-progressbar';
import { FC, PropsWithChildren } from 'react';
import Fonts from 'styles/fonts';
import { buildCombinedTheme } from 'theme';
import ColorHelpers from 'utils/helpers/ColorHelpers';

type EmptyProps = Record<string, never>;

interface IWebsiteHocProps extends PropsWithChildren {
  colorTheme: ColorTheme;
}

const MyApp: FC<AppProps<ICommonAppProps | EmptyProps>> = ({ Component, pageProps }) => {
  if (Object.keys(pageProps).length === 0) {
    // Rendering a static page
    return (
      <WebsiteHoc colorTheme={ColorTheme.DEFAULT}>
        <Component />
      </WebsiteHoc>
    );
  }

  const {
    businessMetadata,
    cognito,
    businessId,
    consumerId,
    locationPairs,
    dehydratedState,
    metadata,
  } = pageProps;

  // Rendering a dynamic page
  return (
    <WebsiteHoc colorTheme={businessMetadata.colorTheme}>
      <OpenGraph businessMetadata={businessMetadata} />
      <AnalyticsProvider metaPixelId={businessMetadata.metaPixelId ?? undefined}>
        <MarketingConnection marketing={businessMetadata.marketing} />
        <SupportWidget supportWidget={businessMetadata.supportWidget ?? undefined} />
        <AmplifyProvider cognito={cognito}>
          <ReactQueryProvider dehydratedState={dehydratedState}>
            <AppContextProvider
              businessId={businessId}
              initialConsumerId={consumerId ?? undefined}
              locationPairs={locationPairs}
              businessMetadata={businessMetadata}>
              <StripeContextProvider>
                <LoginEnforcer requireLogin={metadata.isLoginRequired} redirectTo={PageRoutes.HOME}>
                  <Component {...pageProps} />
                </LoginEnforcer>
              </StripeContextProvider>
            </AppContextProvider>
          </ReactQueryProvider>
        </AmplifyProvider>
      </AnalyticsProvider>
    </WebsiteHoc>
  );
};

// Things we should render on every page, including static pages
const WebsiteHoc: FC<IWebsiteHocProps> = ({ colorTheme, children }) => {
  const colorScheme = ColorHelpers.retrieveColor(colorTheme, 'cottage_green');
  const combinedTheme = buildCombinedTheme(colorScheme);
  return (
    <FrontendMonitoringProvider>
      <NextNProgress color={combinedTheme.colors[`${colorScheme}`]['500']} />
      <ChakraProvider theme={combinedTheme}>
        <Fonts />
        <GAnalytics />
        {children}
      </ChakraProvider>
    </FrontendMonitoringProvider>
  );
};

export default MyApp;
