import { useAmplify } from 'context/Amplify';
import { AuthEventType } from 'context/Amplify/context';
import { BusinessMetadata, LocationPair } from 'custom-types';
import { useRouter } from 'next/router';
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { getLogger } from 'utils';

import { AppContext } from './context';

interface IAppContextProviderProps extends PropsWithChildren {
  businessId: string;
  businessMetadata: BusinessMetadata;
  locationPairs: LocationPair[];
  /**
   * The consumerId at time of page load.
   *
   * The consumerId can change in response to auth events (sign in, sign out, session expiration,
   * etc). The AppContextProvider will provide the latest consumerId to child components.
   */
  initialConsumerId: string | undefined;
}

export const AppContextProvider: FC<IAppContextProviderProps> = ({
  businessId,
  initialConsumerId,
  locationPairs,
  businessMetadata,
  children,
}) => {
  const logger = useMemo(() => getLogger('AppContextProvider'), []);
  const {
    isLoggedIn,
    eventEmitter: { useSubscription },
  } = useAmplify();
  const router = useRouter();
  const [consumerId, setConsumerId] = useState(initialConsumerId);
  const [isValidAuthState, setIsValidAuthState] = useState(false);

  useSubscription((authEvent) => {
    if (authEvent.type === AuthEventType.SignIn) {
      setConsumerId(authEvent.currentUser.username);
    } else {
      setConsumerId(undefined);
    }
  });

  useEffect(() => {
    let isBadAuthState = false;

    if (consumerId && !isLoggedIn) {
      logger.warn(
        'on initial render, consumerId is set, but Amplify thinks the user is not logged in'
      );
      isBadAuthState = true;
    } else if (!consumerId && isLoggedIn) {
      logger.warn(
        'on initial render, consumerId is not set, but Amplify thinks the user is logged in'
      );
      isBadAuthState = true;
    }

    // If the auth state is invalid, attempt to reload the page once.
    const canReload = !localStorage.getItem('didReload');

    if (isBadAuthState && canReload) {
      logger.debug('reloading the page in attempt to resolve auth mismatch');
      localStorage.setItem('didReload', 'true');
      router.reload();
      return;
    }

    localStorage.removeItem('didReload');

    if (isBadAuthState) {
      logger.error(
        'Could not recover from auth mismatch. Rendering children anyway (this will probably cause errors).'
      );
    }

    setIsValidAuthState(true);
  }, []);

  if (!isValidAuthState) {
    // not ready to render children yet
    return;
  }

  return (
    <AppContext.Provider
      value={{
        locationPairs,
        consumerId,
        businessId,
        businessMetadata,
      }}>
      {children}
    </AppContext.Provider>
  );
};
