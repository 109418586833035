import { css, Global } from '@emotion/react';

const Fonts = () => (
  <Global
    styles={css`
      /* Bold */
      @font-face {
        font-style: normal;
        font-weight: 700;
        font-family: 'DaikonFont';
        src:
          local('DaikonFont'),
          url(/fonts/Daikon/daikon-bold-webfont.woff2) format('woff2');
        font-display: swap;
      }
      /* Semibold */
      @font-face {
        font-style: normal;
        font-weight: 600;
        font-family: 'DaikonFont';
        src:
          local('DaikonFont'),
          url(/fonts/Daikon/daikon-semibold-webfont.woff2) format('woff2');
        font-display: swap;
      }
      /* Medium */
      @font-face {
        font-style: normal;
        font-weight: 500;
        font-family: 'DaikonFont';
        src:
          local('DaikonFont'),
          url(/fonts/Daikon/daikon-medium-webfont.woff2) format('woff2');
        font-display: swap;
      }
      /* Regular */
      @font-face {
        font-style: normal;
        font-weight: 400;
        font-family: 'DaikonFont';
        src:
          local('DaikonFont'),
          url(/fonts/Daikon/daikon-regular-webfont.woff2) format('woff2');
        font-display: swap;
      }

      /* Regular */
      @font-face {
        font-style: normal;
        font-weight: 400;
        font-family: 'Slick';
        src:
          local('Slick'),
          url(/fonts/Slick/slick-regular-webfont.woff2) format('woff2');
        font-display: swap;
      }
      .slick-next:before,
      .slick-prev:before,
      .slick-dots li button:before {
        font-family: 'Slick' !important;
      }

      * {
        font-family: 'DaikonFont' !important;
      }
    `}
  />
);

export default Fonts;
