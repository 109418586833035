import { AuthClass } from '@aws-amplify/auth/lib-esm/Auth';
import { AmplifyClass } from '@aws-amplify/core';
import { getLogger } from 'utils';

export interface CognitoAuthConfig {
  userPoolId: string;
  identityPoolId: string;
  userPoolWebClientId: string;
}

const defaultConfig: CognitoAuthConfig = {
  userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID!,
  identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID!,
  userPoolWebClientId: process.env.NEXT_PUBLIC_APP_CLIENT_ID!,
};

const configureAmplify = (amplify: AmplifyClass, config: CognitoAuthConfig) => {
  const region = process.env.NEXT_PUBLIC_REGION!;

  amplify.configure({
    Auth: {
      mandatorySignIn: false,
      region,
      userPoolId: config.userPoolId,
      identityPoolId: config.identityPoolId,
      userPoolWebClientId: config.userPoolWebClientId,
    },
    API: {
      graphql_endpoint:
        process.env.NEXT_PUBLIC_STAGE === 'local'
          ? 'http://localhost:4001/graphql'
          : `${process.env.NEXT_PUBLIC_API_ENDPOINT}/graphql`,
      graphql_endpoint_iam_region: region,
      graphql_headers: async () => {
        const auth: AuthClass = amplify.Auth;
        const log = getLogger('graphql_headers');

        const result: Record<string, string> = {};

        try {
          await auth.currentAuthenticatedUser();
        } catch (e) {
          log.debug('Not authenticated. Skipping token header.');
          return result;
        }

        const session = await auth.currentSession();
        const idToken = session.getIdToken().getJwtToken();
        result['X-Cottage-Token'] = idToken;
        return result;
      },
    },
    ssr: true,
    cookieStorage: {
      domain: null,
      sameSite: 'lax',
    },
  });
};

export const configureAmplifyGenerically = (amplify: AmplifyClass) => {
  console.debug('Configuring generic amplify client');
  configureAmplify(amplify, defaultConfig);
};

export const configureAmplifyForBusiness = (amplify: AmplifyClass, config: CognitoAuthConfig) => {
  console.debug('Configuring business amplify client');
  configureAmplify(amplify, config);
};
