import { ConversionEvent } from 'constants/analytics';

import { AdEventPublisher, ConversionMetadata, EventPublisher } from './EventPublisher';
import { MetaEventPublisher } from './MetaEventPublisher';

export class CompositeEventPublisher implements EventPublisher {
  constructor(private publishers: AdEventPublisher[]) {}

  public static create() {
    return new CompositeEventPublisher([
      MetaEventPublisher.instance,
      // Add event publishers here for other ad networks like Google, TikTok, etc.
    ]);
  }

  public publish(event: ConversionEvent, metadata?: ConversionMetadata) {
    for (const publisher of this.publishers) {
      try {
        publisher.publish(event, metadata);
      } catch (e) {
        console.warn(`Failed to publish analytics event to ${publisher.name}`, { cause: e });
      }
    }
  }
}
