import { type useEventEmitter } from 'ahooks';
import { CurrentUserInfo } from 'auth';
import { createContext, useContext } from 'react';

type EventEmitter<T> = ReturnType<typeof useEventEmitter<T>>;

export interface IAmplifyContext {
  /** Whether the user is authenticated. This will be false until Amplify has finished loading. */
  isLoggedIn: boolean;

  /** The current user. This will be undefined until Amplify has finished loading. */
  loggedInUser?: CurrentUserInfo;

  /** Allows child components to receive events when the user logs in/out. */
  eventEmitter: EventEmitter<AuthEvent>;
}

export const enum AuthEventType {
  SignIn = 'SignIn',
  SignOut = 'SignOut',
}

export type AuthEvent =
  | {
      type: AuthEventType.SignIn;
      currentUser: CurrentUserInfo;
    }
  | { type: AuthEventType.SignOut };

export const AmplifyContext = createContext<IAmplifyContext | undefined>(undefined);

export function useAmplify(): IAmplifyContext {
  const context = useContext(AmplifyContext);
  if (!context) {
    throw new Error('useAmplify must be used within a AmplifyProvider');
  }
  return context;
}
