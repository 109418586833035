import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const helpers = createMultiStyleConfigHelpers([
  'root',
  'flex',
  'textContainer',
  'bannerText',
  'closeContainer',
  'closeButton',
  'closeIcon',
]);

export const CottageBanner = helpers.defineMultiStyleConfig({
  baseStyle: {
    root: {
      height: { base: '30px', md: '40px' },
      width: '100%',
      color: 'white',
      fontWeight: '600',
      background: 'brand.300',
    },

    flex: {
      px: { base: '16px', md: '11.1%' },
    },

    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      lineHeight: { base: '30px', md: '40px' },
    },

    bannerText: {
      fontSize: { base: '13px', md: '14px' },
      textAlign: 'center',
      letterSpacing: '0.1px',
    },

    closeContainer: {
      top: { base: '5px', md: '10px' },
      right: { base: '5px', md: '10px' },
      lineHeight: { base: '0px' },
    },

    closeButton: {
      height: '20px',
      width: '20px',
      minWidth: 'unset',
      padding: '1px',
      lineHeight: 0,
    },

    closeIcon: {
      height: '14px',
      width: '14px',
    },
  },
  variants: {
    floating: {
      root: {
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 30,
      },
    },
  },
  defaultProps: {
    variant: 'floating',
  },
});
