import { loadStripe } from '@stripe/stripe-js';
import { useAppContext } from 'context/AppContext';
import { FC, PropsWithChildren, useMemo } from 'react';

import { StripeContext } from './context';

const config = {
  stripePublisherKey: process.env.NEXT_PUBLIC_STRIPE_PUBLISHER_KEY,
};

export const StripeContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { businessId } = useAppContext();

  const stripePromise = useMemo(() => {
    // Do not inline this check into a variable. Webpack will emit bad code.
    if (!config.stripePublisherKey) {
      throw new Error('Stripe publisher key is missing');
    }
    if (typeof window === 'undefined') {
      // Do not load Stripe on static pages or during SSR
      return null;
    }
    return loadStripe(config.stripePublisherKey, { stripeAccount: businessId });
  }, [businessId]);

  return (
    <StripeContext.Provider value={{ stripe: stripePromise }}>{children}</StripeContext.Provider>
  );
};
