import { useAppContext } from 'context/AppContext';
import { useRouter } from 'next/router';
import { FC, PropsWithChildren } from 'react';

export interface ILoginEnforcerProps extends PropsWithChildren {
  requireLogin: boolean;
  redirectTo: string;
}

export const LoginEnforcer: FC<ILoginEnforcerProps> = ({ requireLogin, redirectTo, children }) => {
  const { consumerId } = useAppContext();
  const router = useRouter();

  if (!requireLogin || consumerId != null) {
    return children;
  }

  router.push(redirectTo);
};
