import { API, APIClass } from 'aws-amplify';
import { MutationFunction, QueryFunction, QueryFunctionContext, QueryKey } from 'react-query';

export function queryWithAmplify<TQueryFnData = unknown, TQueryKey extends QueryKey = QueryKey>(
  queryFn: (
    context: QueryFunctionContext<TQueryKey>,
    api: APIClass
  ) => TQueryFnData | Promise<TQueryFnData>
): QueryFunction<TQueryFnData, TQueryKey> {
  return (context: QueryFunctionContext<TQueryKey>) => queryFn(context, API);
}

export function mutateWithAmplify<TData = unknown, TVariables = void>(
  mutationFn: (variables: TVariables, api: APIClass) => Promise<TData>
): MutationFunction<TData, TVariables> {
  return (variables: TVariables) => mutationFn(variables, API);
}
