/**
 * Conversion events that our application will support
 *
 * For now this is a 1:1 copy of the Meta Standard Events
 */
export const enum ConversionEvent {
  AddPaymentInfo = 'AddPaymentInfo',
  AddToCart = 'AddToCart',
  CompleteRegistration = 'CompleteRegistration',
  // Not currently implemented
  Contact = 'Contact',
  CustomizeProduct = 'CustomizeProduct',
  FindLocation = 'FindLocation',
  InitiateCheckout = 'InitiateCheckout',
  Lead = 'Lead',
  PageView = 'PageView',
  Purchase = 'Purchase',
  // Not currently implemented
  Search = 'Search',
  Subscribe = 'Subscribe',
  ViewContent = 'ViewContent',
}

/** Ad-hoc events recorded in Highlight. These are not reported to ad networks. */
export const enum AdHocTrackingEvent {
  CreatedAccount = 'CreatedAccount',
  ConfirmedAccount = 'ConfirmedAccount',
  SelectedSchedule = 'SelectedSchedule',
  OpenedOfferDetails = 'OpenedOfferDetails',
  AddedItemToCart = 'AddedItemToCart',
  ClickedCheckoutButton = 'ClickedCheckoutButton',
  CompletedCheckout = 'CompletedCheckout',
  PurchasedSubscription = 'PurchasedSubscription',
  PurchasedGiftCard = 'PurchasedGiftCard',
}
