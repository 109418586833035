import { type Stripe } from '@stripe/stripe-js';
import { createContext, useContext } from 'react';

interface IStripeContext {
  stripe: Promise<Stripe | null> | null;
}

export const StripeContext = createContext<IStripeContext | undefined>(undefined);

export function useStripeContext(): IStripeContext {
  const context = useContext(StripeContext);
  if (!context) {
    throw new Error('useStripeContext must be used within a StripeContextProvider');
  }
  return context;
}
