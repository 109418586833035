import { DateTime } from 'luxon';

// Aug 8, 2021
export const displayableDate = (epochTimeStr: string) =>
  DateTime.fromMillis(parseInt(epochTimeStr)).toLocaleString(DateTime.DATE_MED);

// Aug 8
export const displayableDateMonthDay = (epochTimeStr: string) =>
  DateTime.fromMillis(parseInt(epochTimeStr))
    .toLocaleString(DateTime.DATE_MED)
    .split(',')[0]
    .trim();

// Fri, 14 Oct 1983, 9:30 AM
export const displayableDateTimeWithDay = (epochTimeStr: string | number) =>
  DateTime.fromMillis(parseInt(String(epochTimeStr)))
    .setLocale('en-us')
    .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);

export function enumKeys<E extends Record<string, unknown>>(e: E): (keyof E)[] {
  return Object.keys(e) as (keyof E)[];
}

export const toCamelCase = (title: string | null | undefined, delimiter?: string) => {
  if (!title) {
    return '';
  }
  const words = title.split(delimiter || '_');
  const manipulatedWords = words.map((word) => word[0].toUpperCase() + word.toLowerCase().slice(1));
  return manipulatedWords.join(' ');
};
