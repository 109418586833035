export const fonts = {
  heading: 'DaikonFont',
  body: 'DaikonFont',
};

// TODO: for future text fixes
// ignore this for now
export const textStyles = {
  h1: {
    fontSize: {
      base: '1.2rem',
      md: '1.7rem',
    },
    color: 'p.black',
    lineHeight: { base: '1.15em', md: '1.1em' },
  },
  h2: {
    fontSize: {
      base: '1.5rem',
      md: '1.75rem',
    },
    color: 'p.black',

    lineHeight: { base: '1.75rem', md: '2rem' },
  },
  h3: {
    fontSize: {
      base: '1.375rem',
      md: '1.5rem',
    },
    color: 'p.black',

    lineHeight: { base: '1.625rem', md: '1.75rem' },
  },

  h4: {
    fontSize: {
      base: '1.25rem',
      md: '1.375rem',
    },
    color: 'p.black',

    lineHeight: { base: '1.5rem', md: '1.625rem' },
  },
  h5: {
    fontSize: {
      base: '1.125rem',
      md: '1.25rem',
    },
    color: 'p.black',

    lineHeight: { base: '1.375rem', md: '1.5rem' },
  },
  h6: {
    fontSize: {
      base: '1rem',
      md: '1.125rem',
    },
    color: 'p.black',

    lineHeight: { base: '1.25rem', md: '1.375rem' },
  },
};
