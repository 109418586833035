import { AccountTabUriName } from 'custom-types';

export enum PageRoutes {
  NO_STORE = '/nostore',
  DRAFT = '/draft',
  SERVER_DOWN = '/500',
  NOT_FOUND = '/404',

  HOME = '/',
  ABOUT = '/about',
  FAQ = '/faq',

  LOCATIONS = '/locations',
  ACCOUNT = '/account/:accountTabUriName?',

  // order checkout related
  ORDER = '/:pathSegment/order',
  CHECKOUT = '/:pathSegment/order/checkout',
  CHECKOUT_COMPLETE = '/:pathSegment/order/checkout/complete',

  // gift card checkout related
  GIFT_CARDS = '/giftcard',
  GIFT_CARD_CHECKOUT = '/giftcard/checkout',
  GIFT_CARD_CHECKOUT_COMPLETE = '/giftcard/checkout/complete',

  // plan checkout related
  PLANS = '/plans',
  PLANS_CHECKOUT = '/plans/checkout',
  PLANS_CHECKOUT_COMPLETE = '/plans/checkout/complete',

  // level
  TERMS_AND_CONDITIONS = '/legal/terms',
  PRIVACY_POLICY = '/legal/privacy',
}

export interface Params {
  pathSegment?: string;
  accountTabUriName?: AccountTabUriName;
  checkoutId?: string;
  subscriptionId?: string;
  giftCardId?: string;
}
