import { CognitoUser } from '@aws-amplify/auth';
import { CurrentUserOpts } from '@aws-amplify/auth/lib-esm/types';
import { Auth } from 'aws-amplify';
import { getLogger } from 'utils';

import {
  CurrentAuthenticatedUser,
  CurrentUserInfo,
  CUSTOM_ATTR_FIRST_NAME,
  CUSTOM_ATTR_LAST_NAME,
  CUSTOM_ATTR_PHONE_NUMBER,
  CUSTOM_ATTR_SMS_ENABLED,
} from './model';

/**
 * Used for change password flow specifically
 * @returns the current authenticated user
 */
export const getCurrentAuthenticatedUser = async (): Promise<CurrentAuthenticatedUser> =>
  await Auth.currentAuthenticatedUser();

/**
 * Gets the current authenticated user and their attributes.
 *
 * Do not call this on the first render or it may return null.
 */
export const getCurrentUserInfo = async (): Promise<CurrentUserInfo> =>
  await Auth.currentUserInfo();

/**
 * Gets the current authenticated user and their Cognito user attributes. Equivalent to getCurrentUserInfo but lets you
 * avoid this Amplify SDK bug: https://github.com/aws-amplify/amplify-js/issues/10694#issuecomment-1330920811
 */
export const getCurrentUserPoolUser = async (params?: CurrentUserOpts): Promise<CurrentUserInfo> =>
  await Auth.currentUserPoolUser(params);

/** Refresh the current authenticated user's id token */
export const refreshCurrentSession = async () => {
  const log = getLogger('refreshCurrentSession');
  const user = await getCurrentAuthenticatedUser();
  const session = await Auth.currentSession();

  log.debug('refreshing id token');
  await new Promise((resolve, reject) => {
    user.refreshSession(session.getRefreshToken(), (err?: Error, session?: unknown) => {
      if (err) {
        log.warn('failed to refresh session', err);
        reject(err);
      } else {
        resolve(session);
      }
    });
  });
  log.debug('refreshCurrentSession: successfully refreshed id token');
};

export const signIn = async (username: string, password: string) => {
  return await Auth.signIn({ username, password });
};

export const signUp = async (
  username: string,
  password: string,
  firstName: string,
  lastName: string,
  phoneNumber: string,
  smsEnabled: boolean
) =>
  await Auth.signUp({
    username,
    password,
    attributes: {
      [CUSTOM_ATTR_FIRST_NAME]: firstName,
      [CUSTOM_ATTR_LAST_NAME]: lastName,
      [CUSTOM_ATTR_PHONE_NUMBER]: phoneNumber,
      // cognito metadata can only be a number or string
      [CUSTOM_ATTR_SMS_ENABLED]: smsEnabled ? 'true' : 'false',
    },
  });

export const signOut = async () => await Auth.signOut();

export const resendCode = async (email: string) => await Auth.resendSignUp(email);

export const forgotPassword = (email: string) => Auth.forgotPassword(email);

// leverages the code sent to customer's email and provides ability to reset the password
export const resetPassword = async (email: string, password: string, code: string) =>
  await Auth.forgotPasswordSubmit(email, code, password);

export const confirmSignUp = (email: string, confirmationCode: string) =>
  Auth.confirmSignUp(email, confirmationCode);

export const changePassword = async (
  currentUser: CognitoUser,
  oldPassword: string,
  newPassword: string
) => await Auth.changePassword(currentUser, oldPassword, newPassword);
