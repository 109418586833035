import MetaPixel from 'components/MetaPixel';
import { useFrontendMonitoring } from 'context/FrontendMonitoring';
import { FC, PropsWithChildren } from 'react';

import { CompositeEventPublisher } from './CompositeEventPublisher';
import { AnalyticsContext } from './context';
import { MetaEventPublisher } from './MetaEventPublisher';

interface IAnalyticsProviderProps extends PropsWithChildren {
  metaPixelId?: string;
}

const publisher = CompositeEventPublisher.create();
const publishConversionEvent = publisher.publish.bind(publisher);

export const AnalyticsProvider: FC<IAnalyticsProviderProps> = ({ metaPixelId, children }) => {
  const { trackEvent } = useFrontendMonitoring();

  return (
    <>
      <MetaPixel metaPixelId={metaPixelId} publisher={MetaEventPublisher.instance} />

      {/* Add tracking pixels here for other ad networks like Google, TikTok, etc. */}

      <AnalyticsContext.Provider
        value={{
          publishConversionEvent,
          trackEvent,
        }}>
        {children}
      </AnalyticsContext.Provider>
    </>
  );
};
