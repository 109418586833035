const enum LogLevel {
  DEBUG = 'debug',
  LOG = 'log',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  TRACE = 'trace',
}

type LogFunction = (msg: string, ...rest: unknown[]) => void;

type Logger = Record<LogLevel, LogFunction>;

export const getLogger = (scope: string): Logger => {
  const makeLogFn =
    (key: LogLevel) =>
    (msg: string, ...rest: unknown[]) =>
      console[key](`${scope}: ${msg}`, ...rest);

  return {
    debug: makeLogFn(LogLevel.DEBUG),
    log: makeLogFn(LogLevel.LOG),
    info: makeLogFn(LogLevel.INFO),
    warn: makeLogFn(LogLevel.WARN),
    error: makeLogFn(LogLevel.ERROR),
    trace: makeLogFn(LogLevel.TRACE),
  } satisfies Logger;
};
