import { extendTheme } from '@chakra-ui/react';
import colors from 'theme/colors';
import components from 'theme/components';
import styles from 'theme/styles';
import { getSemanticTokens } from 'theme/tokens';
import { fonts } from 'theme/typography';

export const buildCombinedTheme = (colorPrefix: string) => {
  const semanticTokens = getSemanticTokens(colorPrefix);
  return extendTheme({ colors, fonts, components, styles, semanticTokens });
};
