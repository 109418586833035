const colors = {
  // Use 500 for light, 700 for regular, 900 for dark
  cottage_green: {
    50: '#A4CEBA',
    100: '#92C4AC',
    200: '#6DB090',
    300: '#509574',
    400: '#3D7158',
    500: '#235C48',
    600: '#294C3B',
    700: '#1D362A',
    800: '#172C22',
    900: '#122119',
  },
  cottage_blue: {
    50: '#C2C7FB',
    100: '#B5BCFA',
    200: '#9DA6F8',
    300: '#8490F6',
    400: '#6C7AF5',
    500: '#5363F3',
    600: '#283CF0',
    700: '#0F23DA',
    800: '#0C1CAE',
    900: '#091583',
  },
  cottage_light_green: {
    500: '#F2F7F2',
    600: '#e8efe8',
    700: '#D8E0D8',
    800: '#D3DEDA',
  },
  cottage_gray: {
    100: '#F8F7F7',
    200: '#E4EEF7',
    300: '#E6E8E5',
    400: '#939C9C',
    500: '#525D5F',
  },
  cottage_red: {
    500: '#EB6237',
  },
  cottage_olive: {
    500: '#102D29',
  },
  cottage_salmon: {
    50: '#fdf0e7',
    100: '#f6d2bc',
    200: '#f2b58f',
    300: '#ef9760',
    400: '#eb7933',
    500: '#d2611d',
    600: '#a34b16',
    700: '#743610',
    800: '#46200a',
    900: '#180a02',
  },
};

export default colors;
