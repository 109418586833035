import {
  ErrorBoundary as HighlightErrorBoundary,
  H,
  HighlightInit,
  Props as HighlightOptions,
} from '@highlight-run/next/client';
import type { FC, PropsWithChildren } from 'react';

import { FrontendMonitoringContext } from './context';

const config = {
  enabledString: process.env.NEXT_PUBLIC_HIGHLIGHT_ENABLED,
  projectId: process.env.NEXT_PUBLIC_HIGHLIGHT_PROJECT_ID,
  environmentName: process.env.NEXT_PUBLIC_HIGHLIGHT_ENVIRONMENT ?? 'development',
};

export const FrontendMonitoringProvider: FC<PropsWithChildren> = ({ children }) => {
  // Do not inline these checks into the variables. Webpack may emit bad code.
  const isEnabled = config.enabledString === 'true';
  const isLocalhost = config.environmentName === 'development';
  const excludedHostnames = !isEnabled && isLocalhost ? ['localhost'] : [];

  const highlightOptions: HighlightOptions = {
    serviceName: 'cottage-shop-web',
    projectId: config.projectId,
    environment: config.environmentName,
    excludedHostnames,
    disableBackgroundRecording: true,
    reportConsoleErrors: true,
    // On localhost, send the assets to highlight so session replay doesn't look terrible
    inlineImages: isLocalhost,
    inlineStylesheet: isLocalhost,
    networkRecording: {
      recordHeadersAndBody: true,
      disableWebSocketEventRecordings: true,
      networkHeadersToRedact: ['Authorization', 'X-Amz-Security-Token', 'X-Cottage-Token'],
      // TODO: refine this list (might be incomplete)
      urlBlocklist: [
        // payments
        'https://js.stripe.com',
        'https://m.stripe.network',
        // login
        'https://cognito-identity.us-east-1.amazonaws.com',
        'https://cognito-idp.us-east-1.amazonaws.com',
        // defaults
        'https://www.googleapis.com/identitytoolkit',
        'https://securetoken.googleapis.com',
      ],
    },
    privacySetting: 'none',
    tracingOrigins: ['localhost', 'api.cottage.menu', 'api.cottage-devo.link'],
  };

  return (
    <FrontendMonitoringContext.Provider
      value={{
        identifyUser: H.identify,
        trackEvent: H.track,
      }}>
      <HighlightInit {...highlightOptions} />
      <HighlightErrorBoundary>{children}</HighlightErrorBoundary>
    </FrontendMonitoringContext.Provider>
  );
};
