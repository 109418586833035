import { createContext, useContext } from 'react';
import { QueryClient } from 'react-query';

export interface IReactQueryContext {
  queryClient: QueryClient;
}

export const ReactQueryContext = createContext<IReactQueryContext | undefined>(undefined);

export function useReactQuery(): IReactQueryContext {
  const context = useContext(ReactQueryContext);
  if (!context) {
    throw new Error('useReactQuery must be used within a ReactQueryProvider');
  }
  return context;
}
