import { FC, PropsWithChildren, useState } from 'react';
import { DehydratedState, hydrate, QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { getLogger } from 'utils';
import { serializeError } from 'utils/errors';

import { ReactQueryContext } from './context';

interface IReactQueryProviderProps extends PropsWithChildren {
  dehydratedState: DehydratedState;
}

export const ReactQueryProvider: FC<IReactQueryProviderProps> = ({ dehydratedState, children }) => {
  const isServerRender = typeof window === 'undefined';
  const queryClientOptions = {
    defaultOptions: {
      queries: {
        // Never, ever re-fetch during a SSR
        enabled: !isServerRender,
        // FIXME: remove this and set it at the component level
        staleTime: isServerRender ? undefined : 180000,
      },
    },
  };
  const [queryClient] = useState(() => new QueryClient(queryClientOptions));

  // Custom logger for react-query
  const log = getLogger('react-query');
  const debug = (e: unknown) => log.debug(serializeError(e));
  const warn = (e: unknown) => log.warn(serializeError(e));
  setLogger({
    log: debug,
    warn: debug,
    error: warn,
  });

  // Query hydration for SSR
  hydrate(queryClient, dehydratedState);

  return (
    <ReactQueryContext.Provider value={{ queryClient }}>
      <QueryClientProvider client={queryClient}>
        {children}
        <ReactQueryDevtools />
      </QueryClientProvider>
    </ReactQueryContext.Provider>
  );
};
