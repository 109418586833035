import { AdHocTrackingEvent, ConversionEvent } from 'constants/analytics';
import { Metadata } from 'context/FrontendMonitoring';
import { createContext, useContext } from 'react';

import { ConversionMetadata } from './EventPublisher';

export interface IAnalyticsContext {
  /** Track an event for internal diagnostic purposes. Not shared with any advertisers. */
  trackEvent: (event: AdHocTrackingEvent, metadata?: Metadata) => void;

  /** Publishes a Standard Event on behalf of the business. This data is shared with advertisers. */
  publishConversionEvent: (event: ConversionEvent, metadata?: ConversionMetadata) => void;
}

export const AnalyticsContext = createContext<IAnalyticsContext | undefined>(undefined);

export function useAnalytics(): IAnalyticsContext {
  const context = useContext(AnalyticsContext);
  if (!context) {
    throw new Error('useAnalytics must be used within an AnalyticsContextProvider');
  }
  return context;
}
