import Script from 'next/script';
import { FC } from 'react';

interface ISupportWidget {
  supportWidget?: string;
}

export const SupportWidget: FC<ISupportWidget> = ({ supportWidget }) => {
  return supportWidget ? (
    <Script id="supportWidgetScript" strategy="lazyOnload">
      {supportWidget}
    </Script>
  ) : null;
};
