import { ConversionEvent } from 'constants/analytics';
import { MetaEventPublisher } from 'context/Analytics/MetaEventPublisher';
import endent from 'endent';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { FC, useEffect } from 'react';

export interface IMetaPixelProps {
  metaPixelId?: string;
  publisher: MetaEventPublisher;
}

const MetaPixelScript: FC<Required<IMetaPixelProps>> = ({ metaPixelId, publisher }) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      publisher.publish(ConversionEvent.PageView);
    };
    handleRouteChange();

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <Script
      id="meta-pixel"
      data-testid="meta-pixel-script"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: endent`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${metaPixelId}');
          `,
      }}
    />
  );
};

const MetaPixelNoScript: FC<Required<Pick<IMetaPixelProps, 'metaPixelId'>>> = ({ metaPixelId }) => (
  <noscript
    data-testid="meta-pixel-noscript"
    dangerouslySetInnerHTML={{
      __html: endent`
        <img height="1" width="1" style="display:none"
          src="https://www.facebook.com/tr?id=${metaPixelId}&ev=PageView&noscript=1"/>
       `,
    }}
  />
);

export const MetaPixel: FC<IMetaPixelProps> = ({ metaPixelId, publisher }) => {
  if (!metaPixelId) {
    return;
  }

  return (
    <>
      <MetaPixelScript metaPixelId={metaPixelId} publisher={publisher} />
      <MetaPixelNoScript metaPixelId={metaPixelId} />
    </>
  );
};
