import { HubCallback, HubCapsule } from '@aws-amplify/core/lib-esm/Hub';
import { useEventEmitter } from 'ahooks';
import { CognitoIdentity } from 'API';
import { configureAmplifyForBusiness, CurrentUserInfo, getCurrentUserPoolUser } from 'auth';
import { Amplify, Hub } from 'aws-amplify';
import { useFrontendMonitoring } from 'context/FrontendMonitoring';
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { getLogger } from 'utils';

import { AmplifyContext, AuthEvent, AuthEventType, IAmplifyContext } from './context';

interface AmplifyProviderProps extends PropsWithChildren {
  cognito: CognitoIdentity;
}

export const AmplifyProvider: FC<AmplifyProviderProps> = ({ cognito, children }) => {
  const logger = useMemo(() => getLogger('AmplifyProvider'), []);
  const { identifyUser } = useFrontendMonitoring();
  const eventEmitter = useEventEmitter<AuthEvent>();

  // Whether we have configured the Amplify SDK and determined if the user is logged in
  const [isInitialized, setIsInitialized] = useState(false);
  // The logged-in user, if it is known
  const [loggedInUser, setLoggedInUser] = useState<CurrentUserInfo | undefined>();

  const listenForAuthChanges = (
    associateSession: (_: CurrentUserInfo) => void,
    disassociateSession: () => void
  ) => {
    const listener: HubCallback = (data: HubCapsule) => {
      logger.debug(`Received ${data.payload.event} event`);

      switch (data.payload.event) {
        case 'signIn':
        case 'autoSignIn':
          associateSession(data.payload.data as CurrentUserInfo);
          break;
        case 'signOut':
          disassociateSession();
          break;
        default:
          break;
      }
    };

    getCurrentUserPoolUser({ bypassCache: false })
      .then((currentUserInfo: CurrentUserInfo) => {
        logger.debug('initializing user as signed in');
        associateSession(currentUserInfo);
      })
      .catch(() => {
        logger.debug('initializing user as not signed in');
      })
      .finally(() => {
        setIsInitialized(true);
      });

    return Hub.listen('auth', listener);
  };

  useEffect(() => {
    configureAmplifyForBusiness(Amplify, {
      userPoolId: cognito.userPoolId,
      identityPoolId: cognito.identityPoolId,
      userPoolWebClientId: cognito.userPoolClientId,
    });

    const associateSession = (currentUser: CurrentUserInfo) => {
      logger.debug('associating session to user');
      identifyUser(currentUser.attributes.email, {
        id: currentUser.attributes.sub,
        email: currentUser.attributes.email,
      });
      setLoggedInUser(currentUser);
      eventEmitter.emit({ type: AuthEventType.SignIn, currentUser });
    };

    const disassociateSession = () => {
      logger.debug('disassociating session');
      setLoggedInUser(undefined);
      eventEmitter.emit({ type: AuthEventType.SignOut });
    };

    return listenForAuthChanges(associateSession, disassociateSession);
  }, [JSON.stringify(cognito)]);

  if (!isInitialized) {
    // Amplify has not finished loading yet, so render nothing
    return;
  }

  const contextValue = {
    isLoggedIn: !!loggedInUser,
    loggedInUser,
    eventEmitter,
  } satisfies IAmplifyContext;

  return <AmplifyContext.Provider value={contextValue}>{children}</AmplifyContext.Provider>;
};
