import { GoogleAnalytics } from '@next/third-parties/google';
import { FC } from 'react';

export interface IGAnalyticsProps {}

export const GAnalytics: FC<IGAnalyticsProps> = () => {
  const gaId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;
  if (!gaId) {
    return <></>;
  }
  return <GoogleAnalytics gaId={gaId} />;
};
